import ProductPageGallery from 'shared/components/ProductPageGallery';
import { mediaQueries } from '@sqs/universal-utils';
// eslint-disable-next-line import/no-unresolved
import '../../../styles-compressed/legacy/products-collection-item-v2.css';

/**
 * Module that caters to the Javascript needs of the Products Collection v2.0
 * This is for the *visitor-facing* product item page (products-2.0.item).
 *
 * @module  squarespace-products-collection-item-v2
 */

YUI.add('squarespace-products-collection-item-v2', function(Y) {


  /**
   * @class       ItemV2
   * @extends     Base
   * @namespace   Squarespace.SystemCollections.Products
   * @constructor
   */
  Y.namespace('Squarespace.SystemCollections.Products').ItemV2 =
  Y.Base.create('systemCollectionsProductsItemV2', Y.Base, [], {

    initializer: function() {

      this._syncSelectedVariantOptions();

      this._bindUI();
      this._syncUI();

      if (this.get('isLightboxEnabled')) {
        this._lightboxifyImages();
      }
    },

    destructor: function() {

      if (this._resizeEvent) {
        this._resizeEvent.detach();
      }
      if (this._tweakChangeEvent) {
        this._tweakChangeEvent.detach();
      }
      if (this._tweakResetEvent) {
        this._tweakResetEvent.detach();
      }
      if (this._tweakCloseEvent) {
        this._tweakCloseEvent.detach();
      }

      var productVariantsEl = this.get('host').one('.product-variants');
      if (productVariantsEl) {
        productVariantsEl.getDOMNode().removeEventListener('variant:change', this._variantChangeEvent);
      }

      Y.Array.each(this._selectChangeEvents, function(selectChangeEvent) {
        selectChangeEvent.detach();
      });

      this._resizeEvent = null;
      this._tweakChangeEvent = null;
      this._tweakResetEvent = null;
      this._tweakCloseEvent = null;
      this._selectChangeEvents = null;
      this._variantChangeEvent = null;

      if (this._zoomInstances && this._zoomInstances.length > 0) {
        this._destroyImageZoomInstances();
      }

      if (this.get('slideshow')) {
        this.get('slideshow').destroy(true);
      }

      if (this.get('lightbox')) {
        this._destroyLightbox();
      }

    },

    _bindUI: function() {

      this._resizeEvent = Y.on('windowresize', function() {
        this._syncUI();
      }, Y.config.win, this);

      this._tweakChangeEvent = Y.Global.on('tweak:change', this._tweakChangeHandler, this);

      this._tweakResetEvent = Y.Global.on('tweak:reset', this._syncUI, this);

      if (window.SQUARESPACE_LOGIN && window.SQUARESPACE_LOGIN.isLoggedIn()) {
        Y.on('beforeunload', function() {
          this.destroy(true);
        }, this);
      }

    },

    _syncUI: function() {

      if (this.get('isProductGallerySlideshow') || this.get('isProductQuickView')) {

        if (!this.get('slideshow')) {
          this._renderSlideshowGallery();
        } else {
          this._refreshSlideshowGallery();
        }

      } else if (this.get('isProductGalleryStacked')) {

        if (!this.get('isRenderedStackedGallery')) {
          this._renderStackedGallery();
        } else {
          this._refreshStackedGallery();
        }

      }

      // Related Items Images
      this._loadRelatedItemImages();

      // Variant Specific Images
      this._attachVariantImageListeners();
    },

    _attachVariantImageListeners: function() {
      var productVariantsEl = this.get('host').one('.product-variants');
      if (!productVariantsEl) {
        return;
      }

      // remove existing event listener
      if (this._variantChangeEvent) {
        productVariantsEl.getDOMNode().removeEventListener('variant:change', this._variantChangeEvent);
        this._variantChangeEvent = null;
      }

      if (this.get('isProductGallerySlideshow') || this.get('isProductQuickView')) {
        this._variantChangeEvent = this._handleSlideshowGalleryVariantChange.bind(this);
        productVariantsEl.getDOMNode().addEventListener('variant:change', this._variantChangeEvent);
      } else if (this.get('isProductGalleryStacked') && !mediaQueries.isMobile()) {
        this._variantChangeEvent = this._handleStackedGalleryVariantChange.bind(this);
        productVariantsEl.getDOMNode().addEventListener('variant:change', this._variantChangeEvent);
      }
    },

    // Reorders the currently selected variant's main image to the top of the gallery, while
    // preserving existing gallery ordering.
    _handleStackedGalleryVariantChange: function(e) {
      var selectedVariant = e.detail.selectedVariant;
      var focusImageId = selectedVariant.mainImageId;
      if (!focusImageId) {
        return;
      }

      var slideContainer = this.get('slideContainer').getDOMNode();

      // move previous focus image back to original order in stack
      var previousFocusImage = slideContainer.querySelector('.focus-image');
      if (previousFocusImage) {
        previousFocusImage.classList.remove('focus-image');
        var previousIndex = previousFocusImage.getAttribute('data-slide-index');
        slideContainer.insertBefore(previousFocusImage, slideContainer.children[previousIndex]);
      }

      // move current focus image to top of stack
      var focusImage = slideContainer.querySelector('[data-image-id="' + focusImageId + '"]');
      if (focusImage) {
        focusImage.classList.add('focus-image');
        slideContainer.insertBefore(focusImage, slideContainer.firstChild);
      }
    },

    _handleSlideshowGalleryVariantChange: function(e) {
      var slideContainer = this.get('slideContainer');

      if (!slideContainer) {
        return;
      }

      var slides = slideContainer.getDOMNode();
      var slideshow = this.get('slideshow');

      var selectedVariant = e.detail.selectedVariant;
      if (!selectedVariant.mainImageId) {
        return;
      }
      var focusImageId = selectedVariant.mainImageId;
      var focusImage = slides.querySelector('[data-image-id="' + focusImageId + '"]');
      if (!focusImage) {
        return;
      }
      var slideIndex = focusImage.getAttribute('data-slide-index');
      if (slideIndex) {
        slideshow.setNewIndex(slideIndex - 1);
      }
    },

    _tweakChangeHandler: function(field) {

      switch (field.getName()) {

      case 'tweak-product-item-spacing':
      case 'tweak-product-item-gallery-design':
      case 'tweak-product-item-gallery-width':
      case 'tweak-product-item-gallery-aspect-ratio':
      case 'tweak-product-item-gallery-thumbnail-width':
        this._syncUI();

      case 'tweak-product-item-image-zoom-enabled': // eslint-disable-line no-fallthrough
      case 'tweak-product-item-image-zoom-behavior':
      case 'tweak-product-item-image-zoom-factor':
        if (this.get('isImageZoomEnabled')) {
          this._instantiateZoom();
        } else {
          this._destroyImageZoomInstances();
        }

      case 'tweak-product-item-lightbox-enabled': // eslint-disable-line no-fallthrough
        if (this.get('isLightboxEnabled')) {
          this._lightboxifyImages();
        } else if (this.get('lightbox')) {
          this._destroyLightbox();
        }
        break;

      case 'tweak-related-products-items-per-row':
      case 'tweak-related-products-item-spacing':
      case 'tweak-related-products-image-aspect-ratio':
        this._loadRelatedItemImages();
        break;
      }
    },

    /**
     * The variant select boxes have a pretty façade that covers the unsightly
     * stock UI underneath. Update the text on the façade as new values are
     * selected.
     *
     * @method _syncSelectedVariantOptions
     */
    _syncSelectedVariantOptions: function() {

      this._selectChangeEvents = [];

      this.get('host').all('.variant-option').each(function (variant) {
        var selectWrapper = variant.one('.variant-select-wrapper');
        var select = variant.one('select');
        var defaultText = select.one('option').get('text');

        selectWrapper.setAttribute('data-text', defaultText);

        this._selectChangeEvents.push(variant.delegate('change', function(e) {
          var value = select.get('value');
          var text = value === '' ? defaultText : value;
          selectWrapper.setAttribute('data-text', text);
        }, 'select'));
      }, this);

    },

    _renderSlideshowGallery: function() {

      if (this.get('isRenderedStackedGallery')) {
        this._cleanupStackedGallery();
        this.set('isRenderedStackedGallery', false);
      }

      if (this.get('isImageZoomEnabled')) {
        this._instantiateZoom();
      }

      this._instantiateSlideshow();

    },

    _instantiateSlideshow: function() {

      if (this.get('images').size() < 1) {
        return;
      }

      var slideContainer = this.get('slideContainer').getDOMNode();
      var thumbnailContainer = this.get('thumbnailContainer') && this.get('thumbnailContainer').getDOMNode();
      if (thumbnailContainer) {
        this.set('slideshow', new ProductPageGallery(slideContainer, thumbnailContainer));
      }

      this.get('images').each(function(image) {
        image.plug(Y.Squarespace.Loader2, {
          load: true,
          mode: 'fill'
        });
      });

      this.get('images').each(function (image) {
        image.fire('refresh');
      });

      this._loadSlideshowThumbnails();
    },

    _loadSlideshowThumbnails: function() {

      if (this.get('images').size() > 1) {

        this.get('thumbnails').each(function(thumbnail) {
          thumbnail.plug(Y.Squarespace.Loader2, {
            load: true,
            mode: 'fill'
          });
        });

      }
    },

    _loadRelatedItemImages: function() {

      this._onImageLoadEvents = [];

      this.get('relatedItemImages').each(function(image) {
        if (!image.hasClass('loaded')) {
          image.plug(Y.Squarespace.Loader2, {
            load: true,
            mode: 'fill'
          });

          /**
           * Product status badges are only shown after images are loaded. This mirrors behavior in products-2.0-list.js
           */
          this._onImageLoadEvents.push(image.on('load', function() {
            if (image.hasClass('ProductItem-relatedProducts-image--primary')) {
              image.ancestor('.ProductItem-relatedProducts-item').addClass('image-is-loaded');
            }
          }));
        } else {
          image.fire('refresh');
        }

      }, this);

    },

    _refreshSlideshowGallery: function() {

      this.get('thumbnails').each(function(thumbnail) {
        thumbnail.fire('refresh');
      });

      this.get('images').each(function(image) {
        image.fire('refresh');
      });
    },

    _destroySlideshowAndResetImageStyles: function() {

      this.get('slideshow').destroy(true);

      this.get('images').each(function(image) {
        image.setStyles({
          position: '',
          top: '',
          left: '',
          right: '',
          bottom: '',
          zIndex: '',
          width: '',
          height: '',
          overflow: ''
        });
      });

    },

    _renderStackedGallery: function() {

      if (this.get('slideshow')) {
        this._destroySlideshowAndResetImageStyles();
        this.set('slideshow', null);
      }

      if (this.get('isImageZoomEnabled')) {
        this._instantiateZoom();
      }

      this.get('images').each(function(image) {
        image.plug(Y.Squarespace.Loader2, {
          load: true,
          mode: null
        });
      });

      this.set('isRenderedStackedGallery', true);

    },

    _refreshStackedGallery: function() {

      this.get('images').each(function(image) {
        image.fire('refresh');
      });

    },

    // remove stacked gallery-related styles
    _cleanupStackedGallery: function() {
      var host = this.get('host').getDOMNode();
      var clonedImageNode = host.querySelector('.cloned-focus-image');
      if (clonedImageNode) {
        clonedImageNode.remove();
      }

      var originalImageNode = host.querySelector('.original-focus-image');
      if (originalImageNode) {
        originalImageNode.classList.remove('.original-focus-image');
      }
    },

    _lightboxifyImages: function() {

      if (this.get('images').size() < 1 || this.get('isProductQuickView')) {
        return;
      }

      var lightboxSet = [];
      this.get('images').each(function (image) {
        lightboxSet.push({
          content: image
        });
      });

      var hasControls = this.get('images').size() > 1;

      this._imageClickEvent = this.get('slideContainer').delegate('click', function(e) {

        this.set('lightbox', new Y.Squarespace.Lightbox2({
          controls: {
            previous: hasControls,
            next: hasControls,
          },
          set: lightboxSet,
          currentSetIndex: parseInt(e.currentTarget.getAttribute('data-slide-index'), 10) - 1
        }));

        this.get('lightbox').render();
      }, '.ProductItem-gallery-slides-item', this);

    },

    _destroyLightbox: function() {

      this.get('lightbox').destroy(true);
      this.set('lightbox', null);
      this._imageClickEvent.detach();
      this._imageClickEvent = null;

    },

    _instantiateZoom: function() {

      this._destroyImageZoomInstances();

      this._zoomInstances = [];

      this.get('images').each(function(image) {
        this._zoomInstances.push(new Y.Squarespace.ImageZoom({
          host: image.get('parentNode'),
          behavior: this.get('isImageZoomBehaviorClick') ? 'click' : 'hover',
          zoom: this.get('imageZoomFactor')
        }));
      }, this);
    },

    _destroyImageZoomInstances: function() {

      if (!this._zoomInstances || this._zoomInstances.length < 1) {
        return;
      }

      Y.Array.each(this._zoomInstances, function(zoomInstance) {
        zoomInstance.destroy(true);
      });

      this._zoomInstances = null;

    }

  }, {

    ATTRS: {

      host: {
        value: null,
        validator: function(val) {
          var node = Y.one(val);
          return Y.instanceOf(node, Y.Node);
        },
        writeOnce: true
      },

      slideContainer: {
        valueFn: function() {
          return this.get('host').one('.ProductItem-gallery-slides');
        }
      },

      thumbnailContainer: {
        valueFn: function() {
          return this.get('host').one('.ProductItem-gallery-thumbnails');
        }
      },

      images: {
        valueFn: function() {
          return this.get('host').all('.ProductItem-gallery-slides-item-image');
        }
      },

      thumbnails: {
        valueFn: function() {
          return this.get('host').all('.ProductItem-gallery-thumbnails-item-image');
        }
      },

      relatedItemImages: {
        valueFn: function() {
          return this.get('host').all('.ProductItem-relatedProducts-image');
        }
      },

      isProductQuickView: {
        valueFn: function() {
          return this.get('host').ancestor('.sqs-product-quick-view-lightbox');
        }
      },

      isImageZoomEnabled: {
        getter: function() {
          return Y.one('.tweak-product-item-image-zoom-enabled');
        }
      },

      isImageZoomBehaviorClick: {
        getter: function() {
          return Y.one('.tweak-product-item-image-zoom-behavior-click');
        }
      },

      imageZoomFactor: {
        getter: function() {
          return parseFloat(Y.Squarespace.Template.getTweakValue('tweak-product-item-image-zoom-factor'));
        }
      },

      isLightboxEnabled: {
        getter: function() {
          return Y.one('.tweak-product-item-lightbox-enabled') && !this.get('isImageZoomBehaviorClick');
        }
      },

      isProductGallerySlideshow: {
        getter: function() {
          return Y.one('.tweak-product-item-gallery-design-slideshow');
        }
      },

      isProductGalleryStacked: {
        getter: function() {
          return Y.one('.tweak-product-item-gallery-design-stacked');
        }
      }
    }
  });


  var productItemInstances = [];
  Y.config.win.Squarespace.onInitialize(Y, function() {
    Y.all('.ProductItem.products-collection-v2').each(function(itemEl) {
      // STOP if this is a lightbox, which can happen
      // when onInitialize is called during an ajax
      // load.
      if (itemEl.get('parentNode').hasClass('sqs-product-quick-view-content')) {
        return false;
      }

      // This is usually executed with vanilla js in an
      // inline script tag on products-2.0.item on page
      // load, but it is in here to handle dynamic page
      // loads, where the script does not run.
      itemEl.one('.ProductItem-details .sqs-add-to-cart-button').removeClass('sqs-editable-button');

      productItemInstances.push(
        new Y.Squarespace.SystemCollections.Products.ItemV2({
          host: itemEl
        })
      );
    });
  });

  Y.config.win.Squarespace.onDestroy(Y, function () {
    productItemInstances.forEach(function(instance) {
      instance.destroy();
    });
    productItemInstances.length = 0;
  });

}, '1.0', {
  requires: [
    'base',
    'node',
    'squarespace-beforeunload',
    'squarespace-gallery-ng',
    'squarespace-image-loader',
    'squarespace-image-zoom',
    'squarespace-public-api',
    'squarespace-widgets-lightbox2'
  ]
});

