const SLIDE_CLASS = 'sqs-pdp-gallery-slide';
const ACTIVE_CLASS = 'sqs-pdp-gallery-slide-active';
const TRANSITION_CLASS = 'sqs-pdp-gallery-slide-transition';

const PDP_IMAGE_CLASS = 'ProductItem-gallery-slides-item';
const PDP_THUMBNAIL_CLASS = 'ProductItem-gallery-thumbnails-item';

// maintain compatibility with existing templates / frameworks that rely on the presence of this class
const LEGACY_ACTIVE_CLASS = 'sqs-active-slide';

const DEFAULT_INDEX = 0;

export default class ProductPageGallery {
  private thumbnails: HTMLElement[];
  private images: HTMLElement[];
  private currentIndex = DEFAULT_INDEX;

  constructor(imagesContainer: HTMLDivElement, thumbnailContainer: HTMLDivElement) {

    // initialize images as list of all children of image container
    this.images = Array.from(imagesContainer.getElementsByClassName(PDP_IMAGE_CLASS)) as HTMLElement[];

    this.images.forEach((image, index) => {
      if (index === DEFAULT_INDEX) {
        image.classList.add(ACTIVE_CLASS, LEGACY_ACTIVE_CLASS);
      }
      image.classList.add(SLIDE_CLASS);
    });

    // initialize thumbnails as list of all children of thumbnail container
    this.thumbnails = Array.from(thumbnailContainer.getElementsByClassName(PDP_THUMBNAIL_CLASS)) as HTMLElement[];

    // set up keyboard and click handlers
    this.attachEventListeners();
  }

  public incrementCurrentIndex = () => {
    const newIndex = (this.currentIndex + 1) % this.images.length;
    this.setNewIndex(newIndex);
  };

  public decrementCurrentIndex = () => {
    let newIndex = this.currentIndex - 1;
    newIndex = (newIndex < 0) ? newIndex + this.images.length : newIndex;
    this.setNewIndex(newIndex);
  };

  public setNewIndex = (newIndex: number) => {
    if (newIndex === this.currentIndex) {
      return;
    }

    this.images.forEach((image) => image.classList.remove(TRANSITION_CLASS));
    this.images[newIndex].classList.add(TRANSITION_CLASS);
    this.images[this.currentIndex].classList.add(TRANSITION_CLASS);

    this.setInactiveImage(this.currentIndex);
    this.setActiveImage(newIndex);
    this.currentIndex = newIndex;
  };

  public destroy = () => {
    // remove all active gallery classes from images
    this.images.forEach((image) => {
      image.classList.remove(ACTIVE_CLASS, LEGACY_ACTIVE_CLASS, SLIDE_CLASS, TRANSITION_CLASS);
    });

    // remove all event listeners
    this.thumbnails.forEach((thumbnail) => {
      thumbnail.removeEventListener('click', this.handleThumbnailClick);
    });

    const iframe = document.querySelector('iframe#sqs-site-frame') as HTMLIFrameElement;
    if (iframe !== null && iframe.contentWindow) {
      iframe.contentWindow.removeEventListener('keyup', this.handleKeypress);
    } else {
      document.removeEventListener('keyup', this.handleKeypress);
    }
  };

  private handleKeypress = (e: KeyboardEvent) => {
    const key = e.key || e.keyCode;
    if (key === 'ArrowRight' || key === 39) {
      this.incrementCurrentIndex();
    } else if (key === 'ArrowLeft' || key === 37) {
      this.decrementCurrentIndex();
    }
  };

  private handleThumbnailClick = (e: MouseEvent) => {
    const thumbnail = e.currentTarget as HTMLElement;
    const thumbnailIndex = thumbnail.getAttribute('data-thumbnail-index');
    if (thumbnailIndex) {
      this.setNewIndex(+thumbnailIndex - 1);
    }
  };

  private setActiveImage = (index: number) => this.images[index].classList.add(ACTIVE_CLASS, LEGACY_ACTIVE_CLASS);

  private setInactiveImage = (index: number) => this.images[index].classList.remove(ACTIVE_CLASS, LEGACY_ACTIVE_CLASS);

  private attachEventListeners = () => {
    // bind thumbnails to event listeners
    this.thumbnails.forEach((thumbnail) => {
      thumbnail.addEventListener('click', this.handleThumbnailClick);
    });

    const iframe = document.querySelector('iframe#sqs-site-frame') as HTMLIFrameElement;
    if (iframe !== null && iframe.contentWindow) {
      // if accessing page through iframe
      iframe.contentWindow.addEventListener('keyup', this.handleKeypress);
    } else {
      // if accessing page normally
      document.addEventListener('keyup', this.handleKeypress);
    }
  };
}
